export type VerificationStepType = "front" | "back" | "selfie";

export type VerificationInstructionsType = {
  key: VerificationStepType;
  title: string;
  subtitle: string;
  dontPhotos: {
    path: string;
    captions: string[];
  }[];
  doPhoto: {
    path: string;
    caption: string;
  };
  tips: string[];
};

export interface VerificationApiType {
  attempts: number;
  attempts_images: {
    image: string;
    inserted_at: string;
    reason: string;
    type: string;
  }[];
  back_path?: string;
  back_uploaded: boolean;
  date_of_birth?: string;
  document_type?: "passport" | "license";
  entity_id: string;
  first_name?: string;
  front_path?: string;
  front_uploaded: boolean;
  id: string;
  inserted_at: string;
  last_name?: string;
  manually_verified_at?: string;
  phone_number: string;
  rejected_at?: string;
  selfie_path?: string;
  selfie_uploaded: boolean;
  updated_at?: string;
  verified: boolean;
  verified_at?: string;
}

export interface VerificationType {
  verified_at: null | string;
  manually_verified_at: null | string;
  front_uploaded: boolean;
  front_path: null | string;
  back_uploaded: boolean;
  back_path: null | string;
  selfie_uploaded: boolean;
  selfie_path: null | string;
  attempts: {
    front: number;
    back: number;
    selfie: number;
  };
  rejected_at: null | string;
}

export enum VerificationReducerActions {
  already_verified = "already_verified",
  needs_manual_verification = "needs_manual_verification",
  start_image_upload = "start_image_upload",
  image_upload_success = "image_upload_success",
  image_upload_error = "image_upload_error",
  selfie_upload_failed = "selfie_upload_failed",
  documents_have_failure = "documents_have_failure",
  documents_completed = "documents_completed",
  selfie_has_failure = "selfie_has_failure",
  documents_failed = "documents_failed",
  post_verification = "post_verification",
  start_finalize_tour = "start_finalize_tour",
  finalize_tour_success = "finalize_tour_success",
  reset_state = "reset_state",
  set_error = "set_error",
  update_attempts = "update_attempts",
}

export interface VerificationReducerStateType {
  checkingVerification: boolean;
  alreadyVerified: boolean;
  needsManualVerification: boolean;
  documentUploadComplete: boolean;
  documentsHaveFailure: boolean;
  selfieHasFailure: boolean;
  documentsFailed: boolean;
  verificationComplete: boolean;
  processing: boolean;
  creatingTour: boolean;
  frontAttempts: number;
  backAttempts: number;
  selfieAttempts: number;
  error: string | null;
}
