declare global {
  namespace JSX {
    interface IntrinsicElements {
      "blinkid-imagecapture-in-browser": {
        recognizers: any;
      };
    }
  }
}

// We are matching these values to the values that Microblink
// uses to represent the documents
export enum DocumentTypes {
  PASSPORT = 25,
  DRIVERS_LICENSE = 2,
  ID = 6,
}
