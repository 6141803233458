import { ApiUnitType, ApiProspectType, UnitType } from "./index";

export type TourStatus = "pending" | "active" | "expired" | "deleted" | null;
export type StatusType = "pending" | "expired" | "active" | "deleted";

export interface ApiTourType {
  codes_added: boolean | null;
  community_access_codes: any[];
  community_map_image_url: string | null;
  deleted_at: string | null;
  engrain_asset_id: string | null;
  engrain_map_url: string | null;
  engrain_pricing_process_id: string | null;
  geolocation_required: boolean;
  group_id: number | null;
  has_community_access_codes: boolean;
  id: number;
  identity_verification_result:
    | "rejected"
    | "verified"
    | "previously_verified"
    | "pending_manual_verification"
    | null;
  is_finalized: boolean;
  prospect: ApiProspectType;
  require_id_verification: boolean;
  scheduled_end: string | null;
  scheduled_start: string | null;
  scheduled_start_now: boolean | null;
  source: string | null;
  token: string;
  tour_access_codes: any[];
  unit_access_codes: {
    code: string;
    credential_type: string;
    make: string;
    access_control_device: {
      id: number;
      internal_name: string;
      unit_id: number;
    };
    error: string | null;
  }[];
  units: ApiUnitType[] | null;
}

export interface TourType {
  codesAdded: boolean | null;
  communityMap: string | null;
  deletedAt: string | null;
  geolocationRequired: boolean;
  hasCommunityAccessCodes: boolean;
  hasError: boolean;
  id: number;
  identityVerificationResult:
    | "rejected"
    | "verified"
    | "previously_verified"
    | "pending_manual_verification"
    | null;
  isFinalized: boolean;
  requireIdVerification: boolean;
  scheduledEnd: string | null;
  scheduledStart: string | null;
  scheduledStartNow: boolean | null;
  status: TourStatus;
  token: string;
  units: UnitType[];
  source: string | null;
}

export const SMARTRENT_SUPPORT_NUMBER = "+18444791555";
